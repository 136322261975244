<template>
    <Layout>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Pending Payments</h4>
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div
                                    id="tickets-table_length"
                                    class="dataTables_length"
                                >
                                    <label
                                        class="d-inline-flex align-items-center"
                                    >
                                        Show&nbsp;
                                        <b-form-select
                                            v-model="perPage"
                                            size="sm"
                                            :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                    id="tickets-table_filter"
                                    class="dataTables_filter text-md-right"
                                >
                                    <label
                                        class="d-inline-flex align-items-center"
                                    >
                                        Search:
                                        <b-form-input
                                            v-model="filter"
                                            type="search"
                                            placeholder="Search..."
                                            class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                :items="pendingPaymentList"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                sort-icon-left
                                :filter-included-fields="filterOn"
                            >
                                <template #cell(status)="data">
                                    <p id="pending" v-if="data">Pending</p>
                                </template>

                                <template v-slot:cell(action)="data">
                                    <b-button
                                        variant="primary"
                                        @click="makePayment(data)"
                                        >Pay Now</b-button
                                    >
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div
                                    class="dataTables_paginate paging_simple_numbers float-right"
                                >
                                    <ul
                                        class="pagination pagination-rounded mb-0"
                                    >
                                        <!-- pagination -->
                                        <b-pagination
                                            v-model="currentPage"
                                            :total-rows="rows"
                                            :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <paymentModal
            ref="paymentWindow"
            :requestId="requestId"
            :referenceNumber="refernceNumber"
            :page="pageName"
            :modalTitle="modalTitle"
            :adminFees="adminFees"
            :urgentFees="urgentFees"
            :adminVatFees="adminVatFees"
            :updatedAdminFees="updatedAdminFees"
            :updatedAdminVatFees="updatedAdminVatFees"
        />
    </Layout>
</template>
<script>
/* eslint-disable no-unused-vars */
import Layout from "../../layouts/main.vue";
import { tableData } from "../tables/dataAdvancedtable";
import { BASE_URL, REQUEST_TYPES } from "../../common";
import axios from "axios";
import paymentModal from "../payment/paymentModal.vue";

export default {
    components: {
        Layout,
        paymentModal,
    },
    data() {
        return {
            requestId: "",
            refernceNumber: "",
            adminFees: "",
            adminVatFees: "",
            urgentFees: "",
            modalTitle: "",
            updatedAdminFees: "",
            updatedAdminVatFees: "",

            tableData: tableData,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100, 500, 1000],
            filter: null,
            filterOn: [],
            sortBy: "age",
            sortDesc: false,
            fields: [
                { key: "id", sortable: true },
                { key: "ref_no", sortable: true, label: "Reference Number" },
                { key: "created_at", sortable: true },
                { key: "status" },
                "Action",
            ],
        };
    },
    mounted() {
        this.$store.dispatch(
            "apidata/fetchPendingPayments",
            localStorage.cs_user_token
        );
    },
    methods: {
        makePayment(data) {
            console.log(data.item);
            this.pageName = REQUEST_TYPES.find(
                (x) => x.id === data.item.requesttype
            ).type;

            if (localStorage.cs_user_token) {
                let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel,
                });
                let payload = {
                    request_type: data.item.requesttype,
                    request_id: data.item.id,
                };

                axios
                    .post(
                        `${BASE_URL}getrequeststermsconditions?token=${localStorage.cs_user_token}`,
                        payload
                    )
                    .then((res) => {
                        if (data.item.requesttype == 23) {
                            this.updatedAdminFees =
                                res.data.response.service_fees *
                                data.item.no_of_drawings;
                            this.updatedAdminVatFees =
                                res.data.response.admin_vat_fees *
                                data.item.no_of_drawings;
                        }

                        if (data.item.requesttype == 24) {
                            this.updatedAdminFees =
                                res.data.response.service_fees *
                                data.item.no_of_remotes;
                            this.updatedAdminVatFees =
                                res.data.response.admin_vat_fees *
                                data.item.no_of_remotes;
                        }
                        if (data.item.requesttype == 25) {
                            this.updatedAdminFees =
                                res.data.response.service_fees *
                                data.item.no_of_trash_bins;
                            this.updatedAdminVatFees =
                                res.data.response.admin_vat_fees *
                                data.item.no_of_trash_bins;
                        }
                        console.log(res.data.response);
                        this.requestId = data.item.id;
                        this.refernceNumber = data.item.ref_no;
                        this.modalTitle =
                            "Payment for " + " " + data.item.ref_no;
                        this.adminFees = res.data.response.service_fees;
                        this.urgentFees = res.data.response.urgent_fees;
                        this.adminVatFees = res.data.response.admin_vat_fees;
                        loader.hide();
                        this.$refs.paymentWindow.showModal();
                    })
                    // eslint-disable-next-line no-unused-vars
                    .catch((err) => {
                        loader.hide();
                    });
            }
        },
    },
    computed: {
        pendingPaymentList() {
            return this.$store.getters["apidata/getPendingPaymentList"];
        },
        filteredPaymentList() {
            return this.pendingPaymentList.filter((payment) => {
                return payment.status === 0;
            });
        },
        rows() {
            return this.filteredPaymentList.length;
        },
    },
};
</script>
<style scoped>
#pending {
    color: #ff635c;
}
</style>
